import { ReactNode } from 'react';
import { type Accept as AcceptType } from 'react-dropzone';
export { type Accept as AcceptType } from 'react-dropzone';

export type DragDropInputProps = {
  /** id for fileupload component */
  id?: string;
  /** form label */
  label?: string;
  /** title */
  title?: ReactNode;
  /** allow to remove file */
  allowRemove?: boolean;
  /** allow to preview file */
  allowPreview?: boolean;
  /** error message */
  error?: string;
  /** description */
  desc?: string;
  /** name field */
  name: string;
  /** helper text */
  helperText?: string;
  /** allowed file type refer to https://react-dropzone.org/#!/Accepting%20specific%20file%20types required */
  accept?: AcceptType;
  /** allowed minimum file size in Bytes, default 0.1 */
  minSize?: number;
  /** allowed maximum file size in Bytes */
  maxSize?: number;
  /** set max files, default 0 (unlimited) */
  maxFiles?: number;
  /** show error state */
  showError?: boolean;
  /** show file picker error state */
  showFilePickerError?: boolean;
  /** on Drop function */
  onDrop?: (files: File[]) => void;
  /** called when uploading to server */
  onUploading?: (uploading: boolean) => void;
  /** called when onChange */
  onChange?: (files) => void;
  /** called when onBlur */
  onBlur?: () => void;
  /** called when onError */
  onError?: (error?: string) => void;
  /** called when files is valid */
  onValid?: () => void;
  /** disabled fileupload component */
  disabled?: boolean;
  /** default value for file upload component */
  defaultValue?: CustomFileType[];
  /** custom error state for fileupload component, this will be used for required state from react hook form */
  isUploading?: boolean;
  /** className for fileupload component */
  className?: string;
  /** upload to server when true */
  useApi?: boolean;
};

export type ErrorType =
  | 'file_format_drag_drop'
  | 'file_size_drag_drop'
  | 'file_too_many_files'
  | string;

export enum CustomFileTypeEnum {
  ACCEPTED = 'accepted',
  UPLOADED = 'uploaded',
  REJECTED = 'rejected',
  FAILED = 'failed',
  LOADING = 'loading',
}
export interface CustomFileType extends File {
  uid: string;
  state: CustomFileTypeEnum;
  error?: string;
  errorType?: ErrorType;
  url?: string;

  added?: boolean;
  removed?: boolean;

  actions: {
    remove: () => void;
    retry: () => void;
  };
}

export interface MainDragAndDropProps extends DragDropInputProps {
  isError?: boolean;
}
