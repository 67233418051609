import { ErrorCode } from 'react-dropzone';

import { ErrorType } from './types';

export const MINIMUM_SHOW_AS_MB = 0.01;

export const ERROR_TYPES: Record<ErrorCode, ErrorType> = {
  [ErrorCode.FileTooLarge]: 'file_size_drag_drop',
  [ErrorCode.FileInvalidType]: 'file_format_drag_drop',
  [ErrorCode.FileTooSmall]: 'file_size_too_small',
  [ErrorCode.TooManyFiles]: 'file_too_many_files',
};
