import { cx } from '@emotion/css';
import { convertFromByte } from '@global-common/helper';
import { Text } from '@global-elements';
import { Close, DocumentFill, Refresh } from '@global-shapes';
import { color } from '@global-styles/color';
import { useCallback, useEffect, useState } from 'react';

import { MINIMUM_SHOW_AS_MB } from './constant';
import styled from './styles';
import { CustomFileType, CustomFileTypeEnum } from './types';

function FileItem({
  file,
  allowRemove = true,
  allowPreview = false,
}: {
  file: CustomFileType;
  allowRemove?: boolean;
  allowPreview?: boolean;
}) {
  const [percent, setPercent] = useState(0);

  const isUploading = file?.state === CustomFileTypeEnum.LOADING;
  const isError =
    file?.state === CustomFileTypeEnum.REJECTED ||
    file?.state === CustomFileTypeEnum.FAILED;
  const isFailed = file?.state === CustomFileTypeEnum.FAILED;
  const isDone = file?.state !== CustomFileTypeEnum.LOADING;

  const fileSizeInKB = convertFromByte(file?.size || 0, 'KB');
  const fileSizeInMB = convertFromByte(file?.size || 0, 'MB');

  // Fake Percentage Process
  useEffect(() => {
    if (file?.state !== CustomFileTypeEnum.LOADING) return;

    const interval = setInterval(() => {
      setPercent((prev) => {
        if (prev < 80) {
          return prev + 20;
        } else if (prev === 80) {
          return prev + 10;
        } else if (prev === 90) {
          return prev + 5;
        } else {
          clearInterval(interval);
          return prev;
        }
      });
    }, 500);

    return () => clearInterval(interval);
  }, [file?.state]);

  const sizeText =
    fileSizeInMB >= MINIMUM_SHOW_AS_MB
      ? `${fileSizeInMB.toFixed(2)} MB`
      : `${fileSizeInKB.toFixed(2)} KB`;

  const onRetry = useCallback(() => {
    setPercent(0);
    file?.actions?.retry();
  }, [file?.actions]);

  const onPreview = useCallback(() => {
    if (!allowPreview || !isDone) return;

    let url;
    if (file instanceof File) {
      url = URL.createObjectURL(file);
    } else {
      url = (file as CustomFileType)?.url;
    }

    window.open(url, '_blank');
  }, [allowPreview, file, isDone]);

  return (
    <div>
      <div
        onClick={onPreview}
        className={cx(styled.itemWrapper(isError), styled.itemAdded, {
          [styled.itemRemove]: file.removed,
        })}
      >
        <div className={styled.fileIcon(isError)}>
          <DocumentFill
            fill={isError ? color.red500 : color.blue500}
            width={20}
            height={20}
          />
        </div>
        <div className={styled.fileDetail}>
          <div className={styled.fileNamePercent}>
            <span className={styled.fileName}>{file?.name}</span>
            {isUploading && (
              <span className={styled.filePercent}>{percent}%</span>
            )}
          </div>

          {isUploading && (
            <div className={styled.progressBar}>
              <div className={styled.innerProgressBar(percent, isError)} />
            </div>
          )}

          {isDone && <span className={styled.fileSize}>{sizeText}</span>}
        </div>
        <div className={styled.actionContainer}>
          {isDone && isFailed && (
            <div className={styled.closeIcon} onClick={onRetry}>
              <Refresh width={20} height={20} fill={color.neutral500} />
            </div>
          )}
          {isDone && allowRemove && (
            <div
              className={styled.closeIcon}
              onClick={(e) => {
                e?.stopPropagation();
                file?.actions?.remove();
              }}
            >
              <Close width={20} height={20} fill={color.neutral500} />
            </div>
          )}
        </div>
      </div>
      {isError && !!file?.error && (
        <Text className={styled.errorFile} minSize="xs" maxSize="sm">
          {file?.error}
        </Text>
      )}
    </div>
  );
}

export default FileItem;
