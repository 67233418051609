import { css } from '@emotion/css';
import { screenSize } from '@global-common/size';

const errorLineSvg = `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23DF6464FF' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='12' stroke-linecap='round'/%3e%3c/svg%3e")`;
const lineSvg = `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%2330A6FFFF' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='12' stroke-linecap='round'/%3e%3c/svg%3e")`;

export default {
  root: css`
    width: 100%;
  `,
  label: css`
    margin-bottom: 0.5rem;
  `,
  disabled: css`
    > * {
      opacity: 0.6 !important;
      filter: grayscale(1) !important;
    }
  `,
  hide: css`
    .ant-upload {
      display: none;
    }
  `,
  wrapper: (error) => css`
    padding: 32px 45.5px;
    background-image: ${error ? errorLineSvg : lineSvg};
    border-radius: 8px;
    background-position: center;
    cursor: pointer;
  `,
  innerWrapper: css`
    display: flex;
    flex-direction: column;
  `,
  icon: (error: boolean) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background: ${error ? 'var(--red-100)' : 'var(--blue-100)'};
    margin: 0 auto 1rem;
    padding: 0.6875rem;
    border-radius: 50%;
  `,
  title: css`
    text-align: center;
    color: var(--text-secondary);
  `,

  description: css`
    text-align: center;
    margin-top: 0.5rem;
    color: var(--text-secondary);
  `,
  helperText: css`
    margin-top: 4px;
    text-align: left;
    color: var(--text-secondary);
  `,
  errorText: css`
    color: var(--red-500) !important;
    & * {
      color: var(--red-500) !important;
    }
  `,
  errorFile: css`
    margin-top: 4px;
    color: var(--red-500);
  `,
  itemWrapper: (error) => css`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    background-color: var(--neutral-0);
    padding: 1rem;
    background-image: ${error ? errorLineSvg : lineSvg};
    background-position: center;
    border-radius: 8px;
    min-height: 0px;
    transition: opacity 0.3s;
    opacity: 0;
  `,
  itemAdded: css`
    opacity: 1;
  `,
  itemRemove: css`
    opacity: 0;
  `,
  fileIcon: (error) => css`
    min-width: 2rem;
    min-height: 2rem;
    background: ${error ? 'var(--red-100)' : 'var(--blue-100)'};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  `,
  fileDetail: css`
    width: 100%;
    display: flex;
    flex-direction: column;
  `,
  fileName: css`
    font-size: 1rem;
    width: 100%;
    white-space: normal;
    overflow-wrap: break-word; /* Modern standard for breaking words */
    word-break: break-word; /* Fallback for older browsers */
    @media (max-width: ${screenSize.tabletMd}px) {
      font-size: var(--sm-font-size);
    }
  `,
  fileNamePercent: css`
    width: 100%;
    display: flex;
    justify-content: space-between;
  `,
  filePercent: css`
    font-size: 1rem;
    font-weight: var(--bold-font-weight);
    margin-left: 1rem;
    @media (max-width: ${screenSize.tabletMd}px) {
      font-size: var(--sm-font-size);
    }
  `,
  progressBar: css`
    margin-top: 0.5rem;
    position: relative;
    width: 100%;
    height: 0.5rem;
    background: var(--neutral-200);
    border-radius: 0.3125rem;
    @media (max-width: ${screenSize.tabletMd}px) {
      height: 0.3rem;
    }
  `,
  innerProgressBar: (percent, error) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: ${percent}%;
    height: 100%;
    background: ${error ? 'var(--red-500)' : 'var(--blue-500)'};
    border-radius: 0.3125rem;
    transition: all 0.3s ease-in-out;
  `,
  fileSize: css`
    margin-top: 0.25rem;
    font-size: var(--sm-font-size);
    color: var(--neutral-500);
    @media (max-width: ${screenSize.tabletMd}px) {
      font-size: var(--xs-font-size);
    }
  `,
  closeIcon: css`
    min-width: 2rem;
    min-height: 2rem;
    background: var(--neutral-100);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
  `,
  fileContainer: css`
    display: flex;
    flex-direction: column;
    gap: 8px;
  `,
  actionContainer: css`
    display: flex;
    gap: 16px;
    justify-content: center;
  `,
  inputContainer: css`
    margin-bottom: 16px;
  `,
};
