import { generateRandomUUID, moveToNextTick } from '@global-common/helper';
import { useNotification } from '@global-hooks';
import useTranslation from 'next-translate/useTranslation';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { ErrorCode, FileRejection } from 'react-dropzone';

import { ERROR_TYPES } from '../constant';
import {
  CustomFileType,
  CustomFileTypeEnum,
  DragDropInputProps,
  ErrorType,
} from '../types';

export default function useGetLocalProps({
  files,
  setFiles,
  dragDropProps,
  validateFilesFn, // To validate files, calling all callbacks
}: {
  validateFilesFn: (files) => void;
  files: CustomFileType[];
  setFiles: Dispatch<SetStateAction<CustomFileType[]>>;
  dragDropProps: DragDropInputProps;
}) {
  const {
    maxSize = 8, //in MB
    maxFiles = 1,
    onDrop: propOnDrop,
    onChange,
    onValid,
    accept,
    useApi,
    defaultValue,
  } = dragDropProps;

  const { t } = useTranslation();

  const { addNotification } = useNotification();

  const acceptedFormatString = useMemo(() => {
    let stringFormat = '';

    Object?.keys(accept!)?.map((key, idx) => {
      stringFormat += accept![key]?.map((val) => val)?.join(', ');

      if (idx !== Object?.keys(accept!)?.length - 1) {
        stringFormat += ', ';
      }
    });

    return stringFormat;
  }, [accept]);

  const removeFiles = useCallback(
    (uid: string, cfiles: CustomFileType[]) => {
      let changedFiles = cfiles.filter((cfile) => cfile.uid !== uid);

      changedFiles = changedFiles?.map((file) => {
        if (
          changedFiles.length <= maxFiles &&
          file?.state === CustomFileTypeEnum.REJECTED &&
          file?.errorType === ERROR_TYPES['too-many-files']
        ) {
          Object.assign(file, {
            error: null,
            errorType: null,
            state: useApi
              ? CustomFileTypeEnum.LOADING
              : CustomFileTypeEnum.ACCEPTED,
          });
        }

        return file;
      });

      return changedFiles;
    },
    [maxFiles, useApi],
  );

  const actions = (uid) => ({
    remove: () => {
      setFiles((prev) => {
        const updatedFiles = removeFiles(uid, prev);
        moveToNextTick(() => validateFilesFn(updatedFiles));
        onChange?.(updatedFiles);
        return updatedFiles;
      });
    },
    retry: () => {
      setFiles((prev) => {
        const updatedFiles = prev?.map((f) => {
          if (f.uid === uid) {
            Object.assign(f, {
              error: null,
              errorType: null,
              state: useApi
                ? CustomFileTypeEnum.LOADING
                : CustomFileTypeEnum.FAILED,
            });
          }

          return f;
        });
        validateFilesFn(updatedFiles);
        onChange?.(updatedFiles);
        return updatedFiles;
      });
    },
  });

  function localOnDropRejected(rejectedFiles: FileRejection[]) {
    const selectedFiles = rejectedFiles?.map((file: FileRejection) => {
      const errorCode = file?.errors?.[0]?.code;
      const errorType: ErrorType =
        ERROR_TYPES[errorCode as keyof typeof ERROR_TYPES] ?? '';

      const uid = generateRandomUUID();

      const _error = errorType
        ? t(`common:error_${errorType}`, {
            max: maxSize,
            max_file: maxFiles,
            format: acceptedFormatString,
          })
        : '';

      Object.assign(file?.file, {
        uid,
        state: CustomFileTypeEnum.REJECTED,
        actions: actions(uid),
        ...(errorType
          ? {
              error: _error,
              errorType,
            }
          : {}),
      });

      return file?.file as unknown as CustomFileType;
    });

    if (
      selectedFiles?.[0]?.errorType === ERROR_TYPES?.[ErrorCode.TooManyFiles]
    ) {
      addNotification({
        type: 'danger',
        message: t('common:error_file_too_many_files', { max_file: maxFiles }),
      });

      validateFilesFn(selectedFiles);
      return;
    }

    const updatedFiles: CustomFileType[] = [...files, ...selectedFiles];

    // Call onChange Callback
    onChange?.(updatedFiles);
    // Set files to local state
    setFiles(updatedFiles);
    // Revalidate files state
    moveToNextTick(() => {
      validateFilesFn(updatedFiles);
    });
  }

  function localOnDrop(acceptedFiles: File[]) {
    if (!acceptedFiles?.length) return;

    if (acceptedFiles?.length + files.length > maxFiles) {
      const rejectedFiles = acceptedFiles.map((file) => {
        return {
          file,
          errors: [
            {
              code: ErrorCode.TooManyFiles,
            },
          ],
        };
      });

      localOnDropRejected(rejectedFiles as unknown as FileRejection[]);
      return;
    }

    const selectedFiles = acceptedFiles.map((file: File) => {
      const uid = generateRandomUUID();

      Object.assign(file, {
        uid,
        state: useApi
          ? CustomFileTypeEnum.LOADING
          : CustomFileTypeEnum.ACCEPTED,
        actions: actions(uid),
        added: true,
      });

      return file as unknown as CustomFileType;
    });

    const updatedFiles: CustomFileType[] = [...files, ...selectedFiles];

    validateFilesFn?.(updatedFiles);
    setFiles(updatedFiles);
    onChange?.(updatedFiles);
    propOnDrop?.(updatedFiles);
  }

  useEffect(() => {
    function initDefaultValue() {
      if (defaultValue) {
        const value: CustomFileType[] = defaultValue?.map((cur) => {
          return { ...cur, actions: actions(cur?.uid) } as CustomFileType;
        });

        setFiles(value);
      }
    }

    initDefaultValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Calling onValid callback when first render
  useEffect(() => {
    const errorExist = files?.some((file) => !!file.error);

    if (!errorExist) {
      onValid?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    localOnDrop,
    localOnDropRejected,
  };
}
