import { useGetUploadFileUrl, useUploadFile } from '@api-hooks/common';
import { cx } from '@emotion/css';
import { moveToNextTick } from '@global-common/helper';
import {
  isNativeApp,
  sendNativeMessage,
} from '@global-common/native-web-view-bridge';
import { Text } from '@global-elements';
import { useNotification, useTranslation } from '@global-hooks';
import {
  NativeMessageNameEnum,
  NativeWebViewBridgeEventName,
  PermissionValueEnum,
} from '@global-typings/native-web-view-bridge.model';
import { useMobilePermissionStore } from '@stores';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import FileItem from './FileItem';
import MainDragAndDrop from './MainDragAndDrop';
import useGetLocalProps from './hooks/useGetLocalProps';
import styled from './styles';
import {
  CustomFileType,
  CustomFileTypeEnum,
  DragDropInputProps,
} from './types';

export const acceptfile = {
  'image/png': ['.png'],
  'image/jpg': ['.jpg'],
  'image/jpeg': ['.jpeg'],
  'application/ppt': ['.ppt'],
  'application/pptx': ['.pptx'],
  'application/pdf': ['.pdf'],
};

const DragDropInputV2Default = (props: DragDropInputProps) => {
  const { permission, setPermission } = useMobilePermissionStore();
  const { t } = useTranslation();

  const {
    accept = acceptfile,
    label,
    disabled,
    helperText,
    minSize,
    maxSize = 8, //in MB
    maxFiles = 1,
    title,
    defaultValue,
    showError = true,
    showFilePickerError = true,

    className,
    error,
    allowRemove = true,
    allowPreview = false,

    isUploading,
    onUploading,

    useApi,

    onDrop: propOnDrop,
    onError,
    onChange,
    onValid,

    ...restProps
  } = props;

  const MAX_SIZE = maxSize * 1024 * 1024;

  const [files, setFiles] = useState<CustomFileType[]>([]);

  const isError = !!error;
  const errorMessage = error;

  const { addNotification } = useNotification();

  const { mutateAsync: mutateAsyncUploadFile } = useUploadFile({
    onError: ({ message }) => {
      addNotification({ message, type: 'danger' });
    },
  });

  const isPermissionCameraGranted =
    permission?.camera === PermissionValueEnum.GRANTED;

  /*TODO: Need to remove this supporting old version mobile only @william.ho5 */
  useEffect(
    function receivedNativePermission() {
      /**
       * @note to receive camera permission of the app,
       * just camera because in webview no need to file permission
       */
      if (!isNativeApp()) return;
      function receiveNativeMessage(message) {
        const messagePayload = JSON.parse(message.data);

        if (messagePayload.name === NativeMessageNameEnum.CAMERA_PERMISSION) {
          if (messagePayload.data.granted) {
            const inputComponent = document?.getElementById(
              'drag-drop-input',
            ) as HTMLInputElement;

            inputComponent?.click();
          }
        }
      }

      window.addEventListener('message', receiveNativeMessage, true);
      return () => {
        window.removeEventListener('message', receiveNativeMessage, true);
      };
    },
    [isPermissionCameraGranted, setPermission],
  );

  const uploadFileQueue = useMemo(
    () => files.filter((file) => file?.state === CustomFileTypeEnum.LOADING),
    [files],
  );

  const isLoadingUploadFileUrl = useMemo(
    () => files.some((f) => f?.state === CustomFileTypeEnum.LOADING),
    [files],
  );

  /* To validate files, calling all callbacks */
  const validateFilesFn = useCallback(
    (updatedFiles: CustomFileType[]) => {
      const errorFiles = updatedFiles?.filter(
        (file) =>
          file?.state === CustomFileTypeEnum?.REJECTED ||
          file?.state === CustomFileTypeEnum?.FAILED,
      );

      if (errorFiles?.length) {
        onError?.(errorFiles[0]?.error || '');
      } else {
        onValid?.();
      }
    },
    [onError, onValid],
  );

  const _validateFilesFn = useCallback(
    (files: CustomFileType[]) => moveToNextTick(() => validateFilesFn(files)),
    [validateFilesFn],
  );

  const onUpdateSpecificFile = useCallback(
    (file: CustomFileType) => {
      setFiles((prev) => {
        const updatedFiles = prev.map((_file) => {
          if (_file.uid === file.uid) {
            return file;
          }

          return _file;
        });

        onChange?.(updatedFiles);
        _validateFilesFn(updatedFiles);
        return updatedFiles;
      });
    },
    [_validateFilesFn, onChange],
  );

  useGetUploadFileUrl(uploadFileQueue, {
    cacheTime: 0,
    onSuccess: ({ data }) => {
      const file = files.find((item) => item.uid === data?.uid);

      if (!file) {
        return;
      }

      mutateAsyncUploadFile({
        file: {
          ...file,
          originFileObj: file,
        },
        params: data,
        contentType: file?.type,
      })
        .then(() => {
          Object.assign(file, {
            url: data?.inputs?.key,
            s3Url: data?.inputs?.key,
            state: CustomFileTypeEnum.UPLOADED,
          });

          onUpdateSpecificFile(file);
        })
        .catch(() => {
          onError?.(t('common:error_file_upload_server'));

          Object.assign(file, {
            state: CustomFileTypeEnum.FAILED,
            error: t('common:error_file_upload_server'),
          });

          onUpdateSpecificFile(file);
        });
    },
    onError: ({ message, payload }) => {
      const file = files.find((item) => item.uid === payload?.uid);

      if (!file) {
        return;
      }

      Object.assign(file, {
        state: CustomFileTypeEnum.FAILED,
        error: t('common:error_file_upload_server'),
      });

      onError?.(t('common:error_file_upload_server'));
      onUpdateSpecificFile(file);
      addNotification({ message, type: 'danger' });
    },
    enabled: !!useApi,
  });

  const { localOnDrop, localOnDropRejected } = useGetLocalProps({
    files,
    setFiles,
    validateFilesFn: _validateFilesFn,
    dragDropProps: {
      ...props,
      maxSize,
      maxFiles,
      accept,
    },
  });

  const isNewVersion = !!window.appVersion;

  const { getRootProps, getInputProps, inputRef } = useDropzone({
    accept,
    onDrop: (...args) => {
      localOnDrop(args?.[0]);
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    },
    onDropRejected: localOnDropRejected,
    maxSize: MAX_SIZE,
    minSize,
    maxFiles,
    disabled,

    multiple: maxFiles > 1,

    noClick:
      isNativeApp() && (isNewVersion ? !isPermissionCameraGranted : true),
    noDrag: isNativeApp() && (isNewVersion ? !isPermissionCameraGranted : true),
  });

  useEffect(() => {
    onUploading?.(isLoadingUploadFileUrl);
  }, [isLoadingUploadFileUrl, onUploading]);

  const rootProps = getRootProps({
    onClick: () => {
      /*Temporary calling this to support mobile old version */
      if (isNativeApp()) {
        sendNativeMessage({
          name: NativeWebViewBridgeEventName.CAMERA_PERMISSION,
        });
      }

      if (isNativeApp() && !isPermissionCameraGranted) {
        sendNativeMessage({
          name: NativeWebViewBridgeEventName.REQUEST_CAMERA_PERMISSION,
        });
      }
    },
  });

  const inputProps = getInputProps({
    name: name!,
  });

  return (
    <div
      className={cx(styled.root, className, {
        [styled.disabled]: disabled,
      })}
    >
      {!!(files?.length < maxFiles) && (
        <>
          <div {...rootProps} className={styled.inputContainer}>
            {label && (
              <Text
                bold
                size="sm"
                className={cx(styled.label, {
                  [styled.errorText]: isError,
                })}
              >
                {label}
              </Text>
            )}
            <input id="drag-drop-input" {...restProps} {...inputProps} />
            <MainDragAndDrop
              {...props}
              isError={showFilePickerError ? isError : false}
            />
            {(isError || helperText) && (
              <Text
                className={cx(styled.helperText, {
                  [styled.errorText]: isError && showFilePickerError,
                })}
                minSize="xs"
                maxSize="sm"
              >
                {(showError && errorMessage) || helperText}
              </Text>
            )}
          </div>
        </>
      )}
      <div className={styled.fileContainer}>
        {files?.map((_file) => (
          <FileItem
            key={_file?.uid}
            file={_file}
            allowRemove={allowRemove}
            allowPreview={allowPreview}
          />
        ))}
      </div>
    </div>
  );
};

export default function DragDropInputV2<TFieldValues extends FieldValues>(
  props: DragDropInputProps & {
    control?: Control<TFieldValues> | Control<any, any>;
  },
) {
  const { control, name } = props;

  if (control) {
    return (
      <Controller
        control={control}
        name={name as unknown as Path<TFieldValues>}
        render={({ field: { onChange: defaultOnChange }, fieldState }) => (
          <DragDropInputV2Default
            {...props}
            error={fieldState?.error?.message}
            showFilePickerError={fieldState?.error?.type !== 'custom'}
            onChange={(files) => {
              defaultOnChange?.(files);
              props?.onChange?.(files);
            }}
          />
        )}
      />
    );
  }

  return <DragDropInputV2Default {...props} />;
}
