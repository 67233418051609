import { cx } from '@emotion/css';
import { parseHtml, Text } from '@global-elements';
import { useTranslation } from '@global-hooks';
import { DocumentFill } from '@global-shapes';
import { color } from '@global-styles/color';

import styled from './styles';
import { MainDragAndDropProps } from './types';

export default function MainDragAndDrop(props: MainDragAndDropProps) {
  const { isError = false, title, desc, maxSize } = props;

  const { t } = useTranslation();

  return (
    <>
      <div className={styled.wrapper(isError)}>
        <div className={styled.innerWrapper}>
          <div className={styled.icon(isError)}>
            <DocumentFill fill={`${isError ? color.red500 : color.blue500}`} />
          </div>
          <div className={cx(styled.title, { [styled.errorText]: isError })}>
            <Text as="span" minSize="sm" maxSize="p">
              {title || parseHtml(t('upload_file.title'))}
            </Text>
          </div>
          <div
            className={cx(styled.description, {
              [styled.errorText]: isError,
            })}
          >
            <Text as="span" minSize="xs" maxSize="sm">
              {desc ||
                t('upload_file.desc', {
                  max: maxSize,
                })}
            </Text>
          </div>
        </div>
      </div>
    </>
  );
}
